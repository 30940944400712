import humps from 'humps';
import ApiError from './api-error';
import compactObject from './compact-object';

const BASE_URL =
  process.env.NODE_ENV !== 'production'
    ? 'http://localhost:4000'
    : process.env.REACT_APP_API_URL;

export function getURL(path) {
  return `${BASE_URL}/${path.startsWith('/') ? path.slice(1) : path}`;
}

const IDENTITY = (x) => x;

function getQueryString(params) {
  const queryParams = humps.decamelizeKeys(compactObject(params) || {});
  return Object.keys(queryParams)
    .map((key) => `${key}=${queryParams[key]}`)
    .join('&');
}

export function getPath(basePath, params) {
  const queryString = getQueryString(params);
  return params ? `${basePath}?${queryString}` : basePath;
}

function getUrlWithQueryString(path, params) {
  return getURL(getPath(path, params));
}

// eslint-disable-next-line import/prefer-default-export
export async function fetchUrl({
  method,
  data,
  params,
  url,
  transform,
  headers,
} = {}) {
  const options = {
    method: method || 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  };

  const accessToken = localStorage.getItem('jwt');

  if (accessToken) {
    options.headers.Authorization = `Bearer ${accessToken}`;
  }

  if (options.method !== 'GET') {
    options.body = JSON.stringify(humps.decamelizeKeys(data));
  }

  const result = await fetch(
    getUrlWithQueryString(url, compactObject(params)),
    options,
  );

  if (result.ok) {
    const contentType = result.headers.get('content-type');

    if (contentType.includes('application/json')) {
      const json = await result.json();
      return (transform || IDENTITY)(humps.camelizeKeys(json));
    }

    return result;
  }

  const json = await result.json().catch(() => {});
  throw new ApiError(result.status, json);
}
