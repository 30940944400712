import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Layout, Spin, Modal, Row, Col, notification } from 'antd';
import {
  ClockCircleOutlined,
  HourglassOutlined,
  CalendarOutlined,
  PushpinTwoTone,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import esLocale from '@fullcalendar/core/locales/es';
import moment from 'moment';
import 'moment/locale/es';
import { fetchUrl } from '../lib/api';
import { getRoute } from '../lib/routes';
import Navbar from './Navbar';
import Footer from './Footer';

const { Content } = Layout;

const useStyles = createUseStyles({
  layout: {
    minHeight: '100vh',
  },
  contentBox: {
    padding: '10px 50px',
  },
});

const CALENDAR_RULES = {
  timeFormat: {
    hour: '2-digit',
    minute: '2-digit',
  },
};

function Home() {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [isVisibleModal, setVisibleModal] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState({});

  const fetchLessons = (viewInfo) => {
    setLoading(true);
    const params = {
      startDate: moment(viewInfo.currentStart).format('YYYY-MM-DD'),
      endDate: moment(viewInfo.currentEnd)
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
    };
    fetchUrl({ method: 'GET', params, url: '/lessons' })
      .then((respJSON) => {
        const events = respJSON.map((lesson) => {
          const event = {
            id: lesson.id,
            title: lesson.topic.name,
            start: lesson.startsAt,
            end: lesson.endsAt,
          };
          return event;
        });
        setLessons(events);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notification.error({
          message: 'Error',
          description:
            error.message || 'Ha ocurrido un error en la carga de datos',
        });
      });
  };

  const renderDates = (info) => {
    fetchLessons(info.view);
  };

  const openModal = (info) => {
    setSelectedLesson({
      id: info.event.id,
      title: info.event.title,
      startDate: info.event.start,
      endDate: info.event.end,
    });
    setVisibleModal(true);
  };

  return (
    <Layout className={classes.layout}>
      <Navbar />
      <Layout>
        <Content className={classes.contentBox}>
          <Spin tip="Cargando calendario..." spinning={isLoading}>
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              aspectRatio={2}
              locale={esLocale}
              datesRender={renderDates}
              events={lessons}
              eventTimeFormat={CALENDAR_RULES.timeFormat}
              eventClick={openModal}
            />
          </Spin>
        </Content>
      </Layout>
      {isVisibleModal && (
        <Modal
          title={
            <span>
              <PushpinTwoTone twoToneColor="#1890ff" /> Resumen de clase -{' '}
              {selectedLesson.title}
            </span>
          }
          visible
          onCancel={() => {
            setVisibleModal(false);
          }}
          onOk={() => {
            history.push(getRoute('lessons', { id: selectedLesson.id }));
          }}
          cancelText="Cancelar"
          okText="Ver detalles de clase"
        >
          <Row>
            <Col span={8}>
              <CalendarOutlined />
              <b> Día:</b>
            </Col>
            <Col span={16}>{moment(selectedLesson.startDate).format('LL')}</Col>
          </Row>
          <Row>
            <Col span={8}>
              <ClockCircleOutlined />
              <b> Hora de inicio:</b>
            </Col>
            <Col span={16}>
              {moment(selectedLesson.startDate).format('HH:mm')}
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <ClockCircleOutlined />
              <b> Hora de término:</b>
            </Col>
            <Col span={16}>
              {moment(selectedLesson.endDate).format('HH:mm')}
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <HourglassOutlined />
              <b> Duración:</b>
            </Col>
            <Col span={16}>
              {moment(selectedLesson.endDate).diff(
                selectedLesson.startDate,
                'minutes',
              )}{' '}
              minutos
            </Col>
          </Row>
        </Modal>
      )}
      <Footer />
    </Layout>
  );
}

export default Home;
