import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
import { Button, Modal, Input, notification } from 'antd';
import {
  CheckCircleTwoTone,
  WarningTwoTone,
  CloseSquareTwoTone,
} from '@ant-design/icons';
import { fetchUrl } from '../lib/api';

const useStyles = createUseStyles({
  voucherButton: {
    marginLeft: '50px',
  },
});

function LessonVoucherStatus({
  isCanceled,
  endsDate,
  lessonStudent,
  onReload,
}) {
  const classes = useStyles();
  const [isVisibleModal, setVisibleModal] = useState(false);
  const [voucher, setVoucher] = useState('');
  const [isLoading, setLoading] = useState(false);

  const exchangeVoucher = () => {
    setLoading(true);
    const data = {
      voucher,
    };
    fetchUrl({
      method: 'PATCH',
      data,
      url: `lesson_students/${lessonStudent.id}/exchange`,
    })
      .then(() => {
        setLoading(false);
        notification.success({
          description: 'El voucher a sido ingresado',
        });
        onReload();
        setVisibleModal(false);
      })
      .catch((error) => {
        setLoading(false);
        notification.error({
          message: 'Error',
          description:
            error.message || 'Ha ocurrido un error en la carga de datos',
        });
      });
  };

  if (isCanceled) {
    return (
      <p>
        <CloseSquareTwoTone twoToneColor="#ff4d4f" /> Clase cancelada
      </p>
    );
  }
  if (moment().diff(endsDate) <= 0) {
    return <p>Clase Pendiente</p>;
  }
  if (lessonStudent.exchangedAt) {
    return (
      <p>
        <CheckCircleTwoTone twoToneColor="#52c41a" /> Ingresado el día{' '}
        {moment(lessonStudent.exchangedAt).format('LLLL')}
      </p>
    );
  }
  return (
    <>
      <p>
        <WarningTwoTone twoToneColor="#efdd15" /> No ingresado
        <Button
          className={classes.voucherButton}
          type="primary"
          shape="round"
          size="small"
          onClick={() => setVisibleModal(true)}
        >
          Ingresar Voucher
        </Button>
      </p>
      <Modal
        title="Agregar Voucher"
        visible={isVisibleModal}
        cancelText="Cerrar"
        okText="Enviar"
        onOk={exchangeVoucher}
        onCancel={() => {
          setVisibleModal(false);
        }}
        okButtonProps={{ disabled: voucher.length < 4, loading: isLoading }}
      >
        <p>Ingresa el código del voucher de {lessonStudent.student.name}</p>
        <Input
          addonBefore="Código voucher"
          onChange={(event) => {
            setVoucher(event.target.value);
          }}
        />
      </Modal>
    </>
  );
}

LessonVoucherStatus.propTypes = {
  isCanceled: PropTypes.bool.isRequired,
  endsDate: PropTypes.string.isRequired,
  lessonStudent: PropTypes.shape({
    id: PropTypes.number,
    exchangedAt: PropTypes.string,
    student: PropTypes.object,
  }).isRequired,
  onReload: PropTypes.func.isRequired,
};

export default LessonVoucherStatus;
