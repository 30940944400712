import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

function CustomFooter() {
  return (
    <Footer style={{ textAlign: 'center' }}>Hecho con ❤ por Bithalla</Footer>
  );
}

export default CustomFooter;
