import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Modal, Radio, DatePicker, Row, Col } from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import es from 'antd/es/date-picker/locale/es_ES';

const useStyles = createUseStyles({
  inputRadio: {
    display: 'block',
    lineHeight: '30px',
  },
  dateBox: {
    backgroundColor: '#e0ebf5',
    borderRadius: '10px',
    fontWeight: 'bold',
    marginLeft: '25px',
    padding: '5px 15px',
    textAlign: 'center',
  },
});

function AvalilabilityDeletion({
  onClose,
  deletionInfo,
  selectedDay,
  onDelete,
}) {
  const classes = useStyles();
  const [disabledCalendar, setDisabledCalendar] = useState(true);
  const [selectedDates, setSelectedDates] = useState({
    startDate: selectedDay,
    endDate: selectedDay,
  });

  const selectDateRange = (dates) => {
    setSelectedDates({
      startDate: dates[0],
      endDate: dates[1],
    });
  };

  return (
    <Modal
      title="Eliminar disponibilidad"
      visible
      onOk={() => onDelete(selectedDates)}
      onCancel={onClose}
      cancelText="Cancelar"
      okText="Eliminar"
    >
      <div>
        <Radio.Group
          defaultValue
          onChange={(e) => setDisabledCalendar(e.target.value)}
        >
          <Radio className={classes.inputRadio} value>
            <span>Eliminar sólo el bloque del:</span>
            <div className={classes.dateBox}>
              {moment(deletionInfo.startDate).format('dddd LL')} de{' '}
              {moment(deletionInfo.startDate).format('HH:mm')} a{' '}
              {moment(deletionInfo.endDate).format('HH:mm')}
            </div>
          </Radio>
          <Radio className={classes.inputRadio} value={false}>
            Eliminar el bloque de todos los{' '}
            <b>
              {moment(deletionInfo.startDate).format('dddd')} de{' '}
              {moment(deletionInfo.startDate).format('HH:mm')} a{' '}
              {moment(deletionInfo.endDate).format('HH:mm')} entre
            </b>
          </Radio>
          <Row>
            <Col xs={{ span: 16, offset: 4 }} md={{ span: 14, offset: 5 }}>
              <DatePicker.RangePicker
                allowClear={false}
                defaultValue={[selectedDay, selectedDay]}
                format="DD-MM-YYYY"
                locale={es}
                disabled={disabledCalendar}
                onChange={selectDateRange}
              />
            </Col>
          </Row>
        </Radio.Group>
      </div>
    </Modal>
  );
}

AvalilabilityDeletion.propTypes = {
  onClose: PropTypes.func.isRequired,
  deletionInfo: PropTypes.shape({
    id: PropTypes.string,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
  }).isRequired,
  selectedDay: PropTypes.shape({}).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default AvalilabilityDeletion;
