import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { Button, Layout, PageHeader, DatePicker, Tabs } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import moment from 'moment';
import Navbar from '../Navbar';
import useExportExcel from '../../lib/use-export-excel';
import fetcher from '../../lib/fetcher';
import TutorPayments from './TutorPayments';
import ClientPayments from './ClientPayments';

const { TabPane } = Tabs;

function findPaymentPeriod(paymentPeriods, month) {
  return paymentPeriods.find((payment) => {
    const startsAt = moment(payment.startsAt);
    const endsAt = moment(payment.endsAt);
    return startsAt <= month && month <= endsAt;
  });
}

export default function Payments() {
  const [paymentPeriod, setPaymentPeriod] = useState(null);
  const { data: paymentPeriods } = useSWR('/admin/payments', fetcher);
  const { data: lessons, mutate } = useSWR(
    paymentPeriod && `/admin/payments/${paymentPeriod.id}/lessons`,
    fetcher,
  );
  const [generatingTEF, generateTEF] = useExportExcel(
    paymentPeriod && `/admin/payments/${paymentPeriod.id}.xlsx`,
  );

  const onChange = (newMonth) => {
    const selectedPeriod = findPaymentPeriod(paymentPeriods, newMonth);
    if (selectedPeriod) {
      setPaymentPeriod(selectedPeriod);
    }
  };

  const downloadTEF = () => {
    generateTEF();
  };

  const disabledDate = (current) => {
    if (!paymentPeriods || !current) return true;
    return !findPaymentPeriod(paymentPeriods, current);
  };

  useEffect(() => {
    if (paymentPeriods) {
      setPaymentPeriod(paymentPeriods[0]);
    }
  }, [paymentPeriods]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Navbar />
      <Layout.Content style={{ padding: '0 50px' }}>
        <PageHeader
          title="Pagos"
          subTitle="Periodo"
          extra={[
            <DatePicker
              disabledDate={disabledDate}
              value={paymentPeriod && moment(paymentPeriod.startsAt)}
              onChange={onChange}
              picker="month"
              key="1"
            />,
            <Button
              type="primary"
              disabled={!paymentPeriods}
              loading={generatingTEF}
              onClick={downloadTEF}
              icon={<FileExcelOutlined />}
              key="2"
            >
              Descargar TEF
            </Button>,
          ]}
        />
        <Tabs>
          <TabPane tab="Pago Tutores" key="1">
            <TutorPayments onRefresh={mutate} lessons={lessons} />
          </TabPane>
          <TabPane tab="Pago Clientes" key="2">
            <ClientPayments paymentPeriod={paymentPeriod} lessons={lessons} />
          </TabPane>
        </Tabs>
      </Layout.Content>
    </Layout>
  );
}
