import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Descriptions, Button } from 'antd';
import { BankOutlined } from '@ant-design/icons';
import useSWR from 'swr';
import fetcher from '../lib/fetcher';
import UpdateBankAccountModal from './UpdateBankAccountModal';

function BankAccount({ name, email }) {
  const [isVisibleModal, setVisibleModal] = useState(false);
  const defaultBankAccount = useMemo(() => ({ name, email }), [name, email]);
  const { data, mutate } = useSWR('/profile/bank_account', fetcher, {
    fallbackData: defaultBankAccount,
  });
  const bankAccount = data || defaultBankAccount;
  return (
    <Card
      title="Datos de transferencia"
      actions={[
        <Button onClick={() => setVisibleModal(true)}>
          <BankOutlined />
          Actualizar información
        </Button>,
      ]}
    >
      <Descriptions>
        <Descriptions.Item label="Nombre">{bankAccount.name}</Descriptions.Item>
        <Descriptions.Item label="RUT">{bankAccount.rut}</Descriptions.Item>
        <Descriptions.Item label="Banco">{bankAccount.bank}</Descriptions.Item>
        <Descriptions.Item label="Tipo">
          {bankAccount.accountType}
        </Descriptions.Item>
        <Descriptions.Item label="Número Cuenta">
          {bankAccount.number}
        </Descriptions.Item>
        <Descriptions.Item label="Correo">
          {bankAccount.email}
        </Descriptions.Item>
      </Descriptions>
      <UpdateBankAccountModal
        visible={isVisibleModal}
        onCancel={() => setVisibleModal(false)}
        onSuccess={() => {
          setVisibleModal(false);
          mutate();
        }}
        bankAccount={bankAccount}
      />
    </Card>
  );
}

BankAccount.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
};

BankAccount.defaultProps = {
  name: '',
  email: '',
};

export default BankAccount;
