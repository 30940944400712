import React, { useRef, useState } from 'react';
import {
  Alert,
  Layout,
  PageHeader,
  Form,
  Button,
  Spin,
  Input,
  notification,
  Checkbox,
  Card,
  Typography,
} from 'antd';
import { MailOutlined } from '@ant-design/icons';
import useSWR from 'swr';
import { useHistory } from 'react-router-dom';
import fetcher from '../../lib/fetcher';
import { fetchUrl } from '../../lib/api';
import routes from '../../lib/routes';
import Navbar from '../Navbar';
import PageContent from '../PageContent';

const { Title } = Typography;

const validations = {
  email: [
    {
      type: 'email',
      message: 'No es un email válido',
    },
    {
      required: true,
      message: 'Debes ingresar tu email',
    },
  ],
  firstName: [{ required: true, message: 'Es requerido' }],
  lastName: [{ required: true, message: 'Es requerido' }],
};

export default function NewTutor() {
  const [form] = Form.useForm();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const selectedTopics = useRef({});
  const { data: organizations } = useSWR('/admin/organizations', fetcher);
  const { data: topics } = useSWR('/admin/topics', fetcher);

  const createTutor = async (values) => {
    setIsSubmitting(true);

    const payload = {
      ...values,
      authorizedTopicsAttributes: Object.values(selectedTopics.current)
        .flat()
        .map((topicId) => ({ topicId })),
    };

    try {
      await fetchUrl({ method: 'POST', data: payload, url: '/admin/tutors' });
      notification.success({
        message: 'Listo',
        description: '¡Tutor creado!',
      });
      history.push(routes.home);
    } catch (error) {
      notification.error({
        message: '¡Ups!',
        description: error.message || 'Ha ocurrido un error al crear al tutor',
      });
      setIsSubmitting(false);
    }
  };

  const onTopicSelect = (organizationId, ids) => {
    selectedTopics.current[organizationId] = ids;
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Navbar />
      <Layout.Content style={{ padding: '0 50px' }}>
        <PageHeader title="Nuevo tutor" onBack={() => history.goBack()} />
        <PageContent>
          <Alert
            style={{ marginBottom: '1rem' }}
            message="Al crear al tutor, se le enviará un correo para que cree su contraseña."
            type="info"
          />
          <Spin tip="Creando tutor..." spinning={isSubmitting}>
            <Card
              actions={[
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={isSubmitting}
                  onClick={() => form.submit()}
                >
                  Crear tutor
                </Button>,
              ]}
            >
              <Form
                form={form}
                layout="vertical"
                name="login"
                onFinish={createTutor}
              >
                <Title level={4}>Datos personales</Title>
                <Form.Item
                  name="firstName"
                  label="Nombre"
                  rules={validations.firstName}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label="Apellido"
                  rules={validations.lastName}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Correo electrónico"
                  rules={validations.email}
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                  />
                </Form.Item>
                <Title level={4}>Asignaturas</Title>
                {(organizations || []).map((organization) => (
                  <div style={{ marginBottom: '1rem' }}>
                    <h4>{organization.name}</h4>
                    <Checkbox.Group
                      onChange={(values) =>
                        onTopicSelect(organization.id, values)
                      }
                      options={(topics || [])
                        .filter(
                          (topic) => topic.organizationId === organization.id,
                        )
                        .map((topic) => ({
                          label: topic.name,
                          value: topic.id,
                        }))}
                    />
                  </div>
                ))}
              </Form>
            </Card>
          </Spin>
        </PageContent>
      </Layout.Content>
    </Layout>
  );
}
