import PropTypes from 'prop-types';
import { Button, Form, Input, Modal, message, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import { fetchUrl } from '../lib/api';

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const REQUIRED = [
  {
    required: true,
    message: 'Es requerido',
  },
];

const BANK_NAMES = [
  'Banco Santander',
  'Banco Santander Banefe',
  'Banco BBVA',
  'Banco BICE',
  'Banco Internacional',
  'Banco Itaú',
  'Banco de Chile / Edwards-Citi',
  'Corpbanca',
  'Banco Crédito e Inversiones',
  'Banco del Desarrollo',
  'Banco Estado',
  'Banco Falabella',
  'Banco Security',
  'Scotiabank',
  'Rabobank',
  'HSBC Bank',
  'Banco Ripley',
  'Banco Paris',
  'Banco Consorcio',
  'Banco Coopeuch',
];

const ACCOUNT_TYPES = ['Cuenta Corriente', 'Cuenta Vista', 'Cuenta de Ahorro'];

export default function UpdateBankAccountModal({
  bankAccount,
  onCancel,
  onSuccess,
  visible,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue(bankAccount);
  }, [bankAccount, form]);

  const createOrUpdate = (values) => {
    setLoading(true);
    fetchUrl({
      method: bankAccount.id ? 'PUT' : 'POST',
      url: '/profile/bank_account',
      data: { bankAccount: values },
    })
      .then((response) => {
        message.success(
          response.message || 'Datos de transferencia actualizados',
        );
        setLoading(false);
        onSuccess();
      })
      .catch((error) => {
        message.error(error.message || 'No pudimos actualizar tus datos');
        setLoading(false);
      });
  };

  return (
    <Modal
      title="Actualizar datos de transferencia"
      visible={visible}
      onCancel={onCancel}
      getContainer={false}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => form.submit()}
        >
          {loading ? 'Actualizando...' : 'Actualizar'}
        </Button>,
      ]}
    >
      <Form
        initialValues={bankAccount}
        form={form}
        {...layout}
        onFinish={createOrUpdate}
      >
        <Form.Item name="name" label="Tu nombre" rules={REQUIRED}>
          <Input />
        </Form.Item>
        <Form.Item name="rut" label="Tu RUT" rules={REQUIRED}>
          <Input />
        </Form.Item>
        <Form.Item name="bank" label="Banco" rules={REQUIRED}>
          <Select showSearch style={{ width: '100%' }}>
            {BANK_NAMES.map((bankName) => (
              <Option key={bankName} value={bankName}>
                {bankName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="accountType" label="Tipo de cuenta" rules={REQUIRED}>
          <Select style={{ width: '100%' }}>
            {ACCOUNT_TYPES.map((accountType) => (
              <Option key={accountType} value={accountType}>
                {accountType}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="number" label="Número de cuenta" rules={REQUIRED}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Correo electrónico" rules={REQUIRED}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

UpdateBankAccountModal.propTypes = {
  bankAccount: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    rut: PropTypes.string,
    bank: PropTypes.string,
    accountType: PropTypes.string,
    number: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};
