import React, { useState, useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { Form, Input, Button, Card, Row, Col, message } from 'antd';
import { LockOutlined, MailOutlined, FrownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { fetchUrl } from '../lib/api';
import routes from '../lib/routes';
import Logotype from './Logotype';
import { ModeContext } from './AppMode';

const useStyles = createUseStyles({
  centeredItems: {
    textAlign: 'center',
  },
  loginCard: {
    marginTop: '35%',
  },
  alert: {
    marginTop: '20px',
  },
  passwordLink: {
    textAlign: 'right',
  },
});

const EMAIL_RULES = [
  {
    type: 'email',
    message: 'No es un email válido',
  },
  {
    required: true,
    message: 'Debes ingresar tu email',
  },
];

const PASSWORD_RULES = [
  {
    required: true,
    message: 'Debes ingresar tu contraseña',
  },
];

function Login() {
  const classes = useStyles();
  const key = 'updatable';
  const [isLoading, setLoading] = useState(false);
  const { setMode } = useContext(ModeContext);

  const sendUserInfo = (values) => {
    setLoading(true);
    message.loading({ content: 'Cargando...', key });
    const data = { auth: values };
    fetchUrl({ method: 'POST', data, url: '/access_tokens' })
      .then((resJSON) => {
        localStorage.setItem('jwt', resJSON.jwt);
        if (resJSON.role === 'Administrator') {
          localStorage.setItem('isAdmin', true);
          setMode('admin');
        } else {
          setMode('tutor');
        }
        message.success({ content: 'Sesión iniciada', key, duration: 2 });
      })
      .catch(() => {
        setLoading(false);
        message.error({ content: 'Error al inciar sesión', key, duration: 2 });
      });
  };

  return (
    <Row>
      <Col span={24}>
        <Logotype />
      </Col>
      <Col
        xs={{ span: 22, offset: 1 }}
        md={{ span: 10, offset: 7 }}
        lg={{ span: 8, offset: 8 }}
        xl={{ span: 6, offset: 9 }}
      >
        <Card title="Inicio de sesión" className={classes.loginCard}>
          <Form name="login" onFinish={sendUserInfo}>
            <Form.Item name="email" rules={EMAIL_RULES}>
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item name="password" rules={PASSWORD_RULES}>
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item className={classes.centeredItems}>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Ingresar
              </Button>
            </Form.Item>
            <div className={classes.passwordLink}>
              <Link to={routes.passwordRecovery}>
                ¿Olvidaste tu contraseña? <FrownOutlined />
              </Link>
            </div>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default Login;
