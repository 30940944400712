import PropTypes from 'prop-types';
import { Table, Tag } from 'antd';
import React, { useMemo } from 'react';
import LessonsPayments from '../LessonsPayment';

function transform(lessons) {
  const byTutor = {};
  const tutors = [];
  const status = {};
  const payment = {};
  const data = lessons.map((lesson) => ({
    ...lesson,
    payment: lesson.status === 'done' ? lesson.grossPayment : 0,
  }));

  data.forEach((lesson) => {
    const tutorId = lesson.tutor.id;

    if (!byTutor[tutorId]) {
      byTutor[tutorId] = [];
      status[tutorId] = { pending: 0, done: 0 };
      payment[tutorId] = 0;
      tutors.push(lesson.tutor);
    }

    status[tutorId][lesson.status] += 1;
    payment[tutorId] += lesson.payment;
    byTutor[tutorId].push(lesson);
  });

  return tutors
    .map((tutor) => ({
      ...tutor,
      payment: new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP',
      }).format(payment[tutor.id]),
      pending: status[tutor.id].pending,
      done: status[tutor.id].done,
      lessons: byTutor[tutor.id],
    }))
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
}

export default function TutorPayments({ lessons, onRefresh }) {
  const payments = useMemo(() => lessons && transform(lessons), [lessons]);

  return (
    <Table
      pagination={false}
      loading={!payments}
      dataSource={payments}
      rowKey={(record) => record.id}
      expandable={{
        expandedRowRender: (record) => (
          <LessonsPayments
            lessons={record.lessons}
            onRefresh={onRefresh}
            isCancelAllowed
          />
        ),
      }}
      columns={[
        { title: 'Tutor', dataIndex: 'name', key: 'name' },
        { title: 'Clases OK', dataIndex: 'done', key: 'done' },
        {
          title: 'Clases con vouchers pendientes',
          dataIndex: 'pending',
          key: 'pending',
        },
        {
          title: 'Pago Bruto',
          dataIndex: 'payment',
          key: 'pending',
        },
        {
          title: 'Estado',
          key: 'status',
          render: (text, record) => (
            <Tag color={record.pending === 0 ? 'green' : 'red'}>
              {record.pending === 0 ? 'Listo' : 'Faltan Vouchers'}
            </Tag>
          ),
        },
      ]}
    />
  );
}

TutorPayments.propTypes = {
  lessons: PropTypes.arrayOf(PropTypes.shape({})),
  onRefresh: PropTypes.func.isRequired,
};

TutorPayments.defaultProps = {
  lessons: undefined,
};
