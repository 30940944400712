import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Modal, TimePicker, Radio, DatePicker, Row, Col } from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import es from 'antd/es/date-picker/locale/es_ES';

const useStyles = createUseStyles({
  inputRadio: {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  },
  paddingTop: {
    paddingTop: '10px',
  },
});

function AvailabilityForm({ timeRange, selectedDay, onClose, onCreate }) {
  const classes = useStyles();
  const [selectedTime, setSelectedTime] = useState([
    moment(timeRange[0]).format('HH:mm'),
    moment(timeRange[1]).format('HH:mm'),
  ]);
  const [isPickerOpen, setPickerOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState([
    moment(selectedDay).format('YYYY-MM-DD'),
    moment(selectedDay).format('YYYY-MM-DD'),
  ]);

  const handleOk = () => {
    const newRule = {
      start_date: selectedDates[0],
      end_date: selectedDates[1],
      start_time: selectedTime[0],
      end_time: selectedTime[1],
      week_day: moment(selectedDay).day(),
    };
    onCreate(newRule);
    onClose();
  };

  const selectSchedule = (dates, dateStrings) => {
    setSelectedTime(dateStrings);
  };

  const onChange = (event) => {
    setPickerOpen(event.target.value);
  };

  const selectDateRange = (dates) => {
    const dateRange = dates.map((date) => {
      return moment(date).format('YYYY-MM-DD');
    });
    setSelectedDates(dateRange);
  };

  return (
    <Modal
      title="Agregar disponibilidad"
      visible
      onOk={handleOk}
      onCancel={onClose}
      cancelText="Cancelar"
      okText="Guardar"
      okButtonProps={{ disabled: !selectedTime[0] || !selectedTime[1] }}
    >
      <Row>
        <Col xs={{ span: 16, offset: 4 }} md={{ span: 8, offset: 8 }}>
          <TimePicker.RangePicker
            defaultValue={timeRange}
            minuteStep={15}
            format="HH:mm"
            onChange={selectSchedule}
          />
        </Col>
      </Row>
      <div className={classes.paddingTop}>
        <Radio.Group onChange={onChange} defaultValue={false}>
          <Radio className={classes.inputRadio} value={false}>
            Sólo el {moment(selectedDay).format('DD/MM/YYYY')}
          </Radio>
          <Radio className={classes.inputRadio} value>
            Repetir horario
          </Radio>
          {isPickerOpen && (
            <div className={classes.paddingTop}>
              <span>
                Todos los {moment(selectedDay).format('dddd')} entre:{' '}
              </span>
              <DatePicker.RangePicker
                allowClear={false}
                defaultValue={[selectedDay, selectedDay]}
                format="DD-MM-YYYY"
                onChange={selectDateRange}
                locale={es}
              />
            </div>
          )}
        </Radio.Group>
      </div>
    </Modal>
  );
}

AvailabilityForm.propTypes = {
  timeRange: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedDay: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default AvailabilityForm;
