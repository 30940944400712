import React from 'react';
import { Alert } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import useSWR from 'swr';
import fetcher from '../lib/fetcher';

const emptyProfile = {
  firstName: '',
  lastName: '',
};

function ImpersonationAlert() {
  const { data } = useSWR('/profile', fetcher);
  const profile = data || emptyProfile;

  const alertMessage = () => {
    return (
      <>
        <InfoCircleTwoTone
          twoToneColor="#f1c317"
          style={{ fontSize: '16px' }}
        />{' '}
        ATENCIÓN! Estás viendo la sesión de{' '}
        <strong>
          {profile.firstName} {profile.lastName}
        </strong>
      </>
    );
  };

  return (
    <Alert
      style={{ textAlign: 'center' }}
      message={alertMessage()}
      type="warning"
    />
  );
}

export default ImpersonationAlert;
