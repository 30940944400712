import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Card, Row, Col, Form, Input, Button, notification } from 'antd';
import { MailOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Logotype from './Logotype';
import routes from '../lib/routes';
import { fetchUrl } from '../lib/api';

const useStyles = createUseStyles({
  emailCard: {
    marginTop: '10%',
  },
});

const EMAIL_RULES = [
  {
    type: 'email',
    message: 'No es un email válido',
  },
  {
    required: true,
    message: 'Debes ingresar tu email',
  },
];

function PasswordRecovery() {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);

  const sendEmail = (values) => {
    const data = { user: values };
    setLoading(true);
    fetchUrl({ method: 'POST', data, url: '/users/passwords' })
      .then((response) => {
        setLoading(false);
        notification.success({
          message: '¡Listo!',
          description: response.message,
          duration: 0,
        });
      })
      .catch((error) => {
        setLoading(false);
        notification.error({
          message: 'Error',
          description:
            error.message || 'Ha ocurrido un error al enviar los datos',
        });
      });
  };

  return (
    <Row>
      <Col span={24}>
        <Logotype />
      </Col>
      <Col
        xs={{ span: 22, offset: 1 }}
        md={{ span: 18, offset: 3 }}
        xl={{ span: 10, offset: 7 }}
      >
        <Card title="¿Olvidaste tu contraseña?" className={classes.emailCard}>
          <p>Ingresa tu email:</p>
          <Form name="password-recovery" onFinish={sendEmail}>
            <Form.Item
              name="email"
              rules={EMAIL_RULES}
              extra="Te enviaremos un email con las instrucciones para cambiar tu
              constraseña"
            >
              <Input prefix={<MailOutlined />} placeholder="Email" />
            </Form.Item>
            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Enviar instrucciones
              </Button>
            </Form.Item>
          </Form>
          <Link to={routes.login}>
            <ArrowLeftOutlined /> Volver al inicio de sesión
          </Link>
        </Card>
      </Col>
    </Row>
  );
}

export default PasswordRecovery;
