import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import '../styles/App.css';
import AppTutor from './AppTutor';
import AppAdmin from './AppAdmin';
import PublicRoute from './PublicRoute';
import Login from './Login';
import PasswordRecovery from './PasswordRecovery';
import NewPassword from './NewPassword';
import { ModeContext } from './AppMode';
import routes from '../lib/routes';

function App() {
  const { mode } = useContext(ModeContext);

  if (mode === 'admin') return <AppAdmin />;
  if (mode === 'tutor') return <AppTutor />;

  return (
    <Router>
      <Switch>
        <PublicRoute restricted path={routes.login} component={Login} />
        <PublicRoute
          restricted
          path={routes.passwordRecovery}
          component={PasswordRecovery}
        />
        <PublicRoute
          restricted
          path={routes.newPassword}
          component={NewPassword}
        />
        <Redirect to={routes.login} />
      </Switch>
    </Router>
  );
}

export default App;
