import React, { useState, useContext } from 'react';
import { createUseStyles } from 'react-jss';
import {
  Layout,
  PageHeader,
  List,
  Avatar,
  Rate,
  Button,
  Spin,
  Space,
  Input,
  Modal,
  notification,
} from 'antd';
import { ExclamationCircleOutlined, UserAddOutlined } from '@ant-design/icons';

import useSWR from 'swr';
import { useHistory } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import fetcher from '../lib/fetcher';
import { ModeContext } from './AppMode';
import { fetchUrl } from '../lib/api';
import routes, { getRoute } from '../lib/routes';
import PageContent from './PageContent';

const { Content } = Layout;
const { Search } = Input;
const { confirm } = Modal;

const useStyles = createUseStyles({
  listContainer: {
    backgroundColor: '#ffffff',
    marginTop: '20px',
  },
});

function TutorList() {
  const classes = useStyles();
  const { data: tutorList, mutate } = useSWR('/admin/tutors', fetcher);
  const [isFilteredList, setFilteredList] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const { setMode } = useContext(ModeContext);
  const history = useHistory();

  const tutorScore = (score) => {
    return (
      <>
        <Rate disabled allowHalf defaultValue={Math.round(score * 2) / 2} />
        <span> {score}</span>
      </>
    );
  };

  const onSearch = (value) => {
    if (value === '') {
      setFilteredList(false);
    } else {
      const results = tutorList.filter((tutor) => {
        let fullName = `${tutor.firstName.toLowerCase()} ${tutor.lastName.toLowerCase()}`;
        fullName = fullName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const cleanedValue = value
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        return fullName.indexOf(cleanedValue) !== -1;
      });
      setSearchResults(results);
      setFilteredList(true);
    }
  };

  const impersonateUser = (id) => {
    fetchUrl({ method: 'POST', url: `/admin/tutors/${id}/impersonate` })
      .then((respJSON) => {
        const adminJwt = localStorage.getItem('jwt');
        const tutorJwt = respJSON.jwt;
        localStorage.setItem('adminJwt', adminJwt);
        localStorage.setItem('jwt', tutorJwt);
        setMode('tutor');
      })
      .catch((error) => {
        notification.error({
          message: 'Error',
          description:
            error.message || 'Ha ocurrido un error en la impersonalización',
        });
      });
  };

  const deleteUser = (user) => {
    const fullName = `${user.firstName} ${user.lastName}`;
    confirm({
      title: '¿Quieres confirmar la eliminación?',
      icon: <ExclamationCircleOutlined />,
      content: `${fullName} no tendrá acceso al sistema.`,
      async onOk() {
        try {
          await fetchUrl({
            method: 'PUT',
            url: `/admin/tutors/${user.id}`,
            data: { disabled: true },
          });
          notification.success({
            message: 'Listo!',
            description: `${fullName} ya no tiene acceso al sistema.`,
          });
          mutate();
        } catch (error) {
          notification.error({
            message: 'Error',
            description:
              error.message || 'Ha ocurrido un error en la eliminación',
          });
        }
      },
    });
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Navbar />
      <Content style={{ padding: '0 50px' }}>
        <PageHeader
          title="Vista Administrador"
          subTitle="Lista de tutores"
          extra={[
            <Button
              key="newTutor"
              icon={<UserAddOutlined />}
              type="primary"
              onClick={() => history.push(routes.newTutor)}
            >
              Nuevo Tutor
            </Button>,
          ]}
        />
        <PageContent>
          <Spin tip="Cargando lista de tutores..." spinning={!tutorList}>
            <Search
              placeholder="Ingrese nombre del tutor para buscar"
              onSearch={onSearch}
              enterButton
              allowClear
              size="large"
            />
            <List
              className={classes.listContainer}
              bordered
              itemLayout="horizontal"
              dataSource={isFilteredList ? searchResults : tutorList}
              pagination={{ pageSize: 20, showSizeChanger: false }}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  <List.Item.Meta
                    avatar={<Avatar src={item.avatarUrl} />}
                    title={`${item.firstName} ${item.lastName}`}
                    description={tutorScore(item.averageScore)}
                  />
                  <Space>
                    <Button
                      key="showTutor"
                      onClick={() =>
                        history.push(getRoute('showTutor', { id: item.id }))
                      }
                    >
                      Ver perfil
                    </Button>
                    <Button
                      key="impersonate"
                      onClick={() => impersonateUser(item.id)}
                    >
                      Impersonalizar
                    </Button>
                    <Button
                      key="delete"
                      danger
                      onClick={() => deleteUser(item)}
                    >
                      Eliminar
                    </Button>
                  </Space>
                </List.Item>
              )}
            />
          </Spin>
        </PageContent>
      </Content>
      <Footer />
    </Layout>
  );
}

export default TutorList;
