import React from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import TutorList from './TutorList';
import routes from '../lib/routes';
import NewTutor from './Admin/NewTutor';
import ShowTutor from './Admin/ShowTutor';
import EditTutor from './Admin/EditTutor';
import Payments from './Admin/Payments';

function AppAdmin() {
  return (
    <Router>
      <Switch>
        <PrivateRoute path={routes.home} exact component={TutorList} />
        <PrivateRoute path={routes.newTutor} exact component={NewTutor} />
        <PrivateRoute path={routes.showTutor} exact component={ShowTutor} />
        <PrivateRoute path={routes.editTutor} exact component={EditTutor} />
        <PrivateRoute path={routes.payments} exact component={Payments} />
        <Redirect to={routes.home} />
      </Switch>
    </Router>
  );
}

export default AppAdmin;
