import PropTypes from 'prop-types';
import { Button } from 'antd';
import React from 'react';
import { StopOutlined } from '@ant-design/icons';

export default function CancelLessonButton({ lesson, onClick }) {
  if (lesson.externalUrl) {
    return (
      <Button
        href={lesson.externalUrl}
        target="_blank"
        danger
        type="primary"
        icon={<StopOutlined />}
      >
        Cancelar clase
      </Button>
    );
  }

  return (
    <Button danger type="primary" icon={<StopOutlined />} onClick={onClick}>
      Cancelar clase
    </Button>
  );
}

CancelLessonButton.propTypes = {
  lesson: PropTypes.shape({
    externalUrl: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};
