import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import isAuthenticated, { isAdmin, isImpersonatedUser } from '../lib/session';

let initialValue = null;

if (isAuthenticated()) {
  initialValue = isAdmin() && !isImpersonatedUser() ? 'admin' : 'tutor';
}

export const ModeContext = React.createContext(initialValue);

export default function AppMode({ children }) {
  const [mode, setMode] = useState(initialValue);
  const value = useMemo(() => ({ mode, setMode }), [mode]);

  return <ModeContext.Provider value={value}>{children}</ModeContext.Provider>;
}

AppMode.propTypes = {
  children: PropTypes.elementType.isRequired,
};
