import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Card, Checkbox, Row, Col, Button, notification } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { fetchUrl } from '../lib/api';

const useStyles = createUseStyles({
  topicsFooter: {
    marginTop: '15px',
    fontSize: '13px',
    color: '#b3aeae',
  },
});

function getOrganizationsFromTopics(topics) {
  const organizations = {};
  topics.forEach((topic) => {
    const { name, id } = topic.organization;
    organizations[id] = organizations[id] || { name, id, topics: [] };
    organizations[id].topics.push(topic);
  });
  return Object.values(organizations);
}

function TopicSelector({ topics }) {
  const [isLoading, setLoading] = useState(false);
  const [updatedTopics, setTopics] = useState({});
  const classes = useStyles();

  const organizations = getOrganizationsFromTopics(topics);

  const onChange = (event, id) => {
    const topicsCopy = { ...updatedTopics };
    topicsCopy[id] = topicsCopy[id] || { id };
    topicsCopy[id].enabled = event.target.checked;
    setTopics(topicsCopy);
  };

  const saveChanges = () => {
    setLoading(true);
    const data = {
      topics: Object.values(updatedTopics),
    };
    fetchUrl({ method: 'PATCH', data, url: '/profile/topics' })
      .then(() => {
        setLoading(false);
        notification.success({
          message: 'Cambios guardados',
          description: 'Los cambios se guardaron exitosamente',
        });
      })
      .catch((error) => {
        setLoading(false);
        notification.error({
          message: 'Error',
          description:
            error.message || 'Ha ocurrido un error en la carga de datos',
        });
      });
  };

  return (
    <Card
      title="Mis asignaturas"
      actions={[
        <Button
          onClick={saveChanges}
          loading={isLoading}
          disabled={!Object.values(updatedTopics).length}
        >
          <SaveOutlined /> Guardar cambios en asignaturas
        </Button>,
      ]}
    >
      <p>Selecciona las asignaturas en las que quieres hacer tutorias</p>
      <Row>
        {organizations.length
          ? organizations.map((organization) => (
              <Col xs={{ span: 24 }} md={{ span: 12 }} key={organization.id}>
                <h4>{organization.name}</h4>
                {organization.topics.map((topic) => (
                  <div key={topic.id}>
                    <Checkbox
                      defaultChecked={topic.enabled}
                      onChange={(e) => onChange(e, topic.id)}
                    >
                      {topic.name}
                    </Checkbox>
                    <br />
                  </div>
                ))}
              </Col>
            ))
          : 'No te han autorizado asignaturas todavía'}
      </Row>
      <p className={classes.topicsFooter}>
        *Sólo las asignaturas seleccionadas son para las que los alumnos podrán
        ver tu disponibilidad
      </p>
    </Card>
  );
}

TopicSelector.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TopicSelector;
