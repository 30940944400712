import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Layout, PageHeader, Spin, notification } from 'antd';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import esLocale from '@fullcalendar/core/locales/es';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import 'moment/locale/es';
import Navbar from './Navbar';
import Footer from './Footer';
import AvailabilityForm from './AvailabilityForm';
import AvalilabilityDeletion from './AvailabilityDeletion';
import { fetchUrl } from '../lib/api';
import '../styles/availability.css';

const { Content } = Layout;

const useStyles = createUseStyles({
  header: {
    border: '1px solid rgb(235, 237, 240)',
  },
  calendar: {
    height: '50%',
    margin: '20px 0px',
  },
  layout: {
    minHeight: '100vh',
  },
  contentBox: {
    padding: '10px 50px',
  },
});

const CALENDAR_RULES = {
  headerFormat: {
    weekday: 'long',
    month: 'numeric',
    day: 'numeric',
    omitCommas: true,
  },
  titleFormat: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
  labelFormat: {
    hour: 'numeric',
    minute: '2-digit',
    meridiem: 'short',
  },
};

function Availability() {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [availabilities, setAvailabilities] = useState([]);
  const [isVisibleCreationModal, setVisibleCreationModal] = useState(false);
  const [isVisibleDeletionModal, setVisibleDeletionModal] = useState(false);
  const [timeRange, setTimeRange] = useState([]);
  const [selectedDay, setSelectedDay] = useState();
  const [renderizedDates, setRenderizedDates] = useState({});
  const [deletionInfo, setDeletionInfo] = useState({});

  const fetchCalendarRules = (viewInfo) => {
    setLoading(true);
    const params = {
      startDate: moment(viewInfo.currentStart).format('YYYY-MM-DD'),
      endDate: moment(viewInfo.currentEnd)
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
    };
    fetchUrl({ method: 'GET', params, url: '/calendar_rules' })
      .then((respJSON) => {
        const events = respJSON.map((availability) => {
          const event = {
            id: availability.calendarRuleId,
            start: availability.start,
            end: availability.end,
          };
          return event;
        });
        setAvailabilities(events);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notification.error({
          message: 'Error',
          description:
            error.message || 'Ha ocurrido un error en la carga de datos',
        });
      });
  };

  const renderDates = (info) => {
    setRenderizedDates({
      currentStart: info.view.currentStart,
      currentEnd: info.view.currentEnd,
    });
    fetchCalendarRules(info.view);
  };

  const showCreationModal = (info) => {
    setTimeRange([moment(info.date), moment(info.date).add(15, 'minutes')]);
    setSelectedDay(moment(info.date));
    setVisibleCreationModal(true);
  };

  const closeCreationModal = () => {
    setVisibleCreationModal(false);
  };

  const createNewAvailability = (newRule) => {
    setLoading(true);
    fetchUrl({ method: 'POST', data: newRule, url: '/calendar_rules' })
      .then(() => {
        fetchCalendarRules(renderizedDates);
        setLoading(false);
        notification.success({
          description: 'El nuevo bloque horario ha sido agregado',
        });
      })
      .catch((error) => {
        setLoading(false);
        notification.error({
          message: 'Error',
          description:
            error.message || 'Ha ocurrido un error en la carga de datos',
        });
      });
  };

  const showDeletionModal = (info) => {
    setSelectedDay(moment(info.event.start));
    setDeletionInfo({
      id: info.event.id,
      startDate: info.event.start,
      endDate: info.event.end,
    });
    setVisibleDeletionModal(true);
  };

  const closeDeletionModal = () => {
    setVisibleDeletionModal(false);
  };

  const deleteAvailability = (availabilityInfo) => {
    closeDeletionModal();
    setLoading(true);
    const params = {
      startDate: moment(availabilityInfo.startDate).format('YYYY-MM-DD'),
      endDate: moment(availabilityInfo.endDate).format('YYYY-MM-DD'),
    };
    fetchUrl({
      method: 'DELETE',
      params,
      url: `/calendar_rules/${deletionInfo.id}`,
    })
      .then(() => {
        fetchCalendarRules(renderizedDates);
        setLoading(false);
        notification.success({
          description:
            'El bloque horario seleccionado fue eliminado con éxito.',
        });
      })
      .catch((error) => {
        setLoading(false);
        notification.error({
          message: 'Error',
          description:
            error.message || 'Ha ocurrido un error en la carga de datos.',
        });
      });
  };

  return (
    <Layout className={classes.layout}>
      <Navbar />
      <Content className={classes.contentBox}>
        <PageHeader className={classes.header} title="Mi disponibilidad" />
        <Spin tip="Cargando horarios..." spinning={isLoading}>
          <div className={classes.calendar}>
            <FullCalendar
              defaultView="timeGridWeek"
              plugins={[timeGridPlugin, interactionPlugin]}
              allDaySlot={false}
              locale={esLocale}
              columnHeaderFormat={CALENDAR_RULES.headerFormat}
              titleFormat={CALENDAR_RULES.titleFormat}
              minTime="08:00:00"
              maxTime="23:00:00"
              slotDuration="00:15"
              slotLabelInterval="00:15"
              slotLabelFormat={CALENDAR_RULES.labelFormat}
              nowIndicator
              datesRender={renderDates}
              events={availabilities}
              dateClick={showCreationModal}
              eventClick={showDeletionModal}
            />
          </div>
        </Spin>
      </Content>
      {isVisibleCreationModal && (
        <AvailabilityForm
          timeRange={timeRange}
          selectedDay={selectedDay}
          onClose={closeCreationModal}
          onCreate={createNewAvailability}
        />
      )}
      {isVisibleDeletionModal && (
        <AvalilabilityDeletion
          onClose={closeDeletionModal}
          deletionInfo={deletionInfo}
          selectedDay={selectedDay}
          onDelete={deleteAvailability}
        />
      )}
      <Footer />
    </Layout>
  );
}

export default Availability;
