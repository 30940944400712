import React, { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { Layout, Menu } from 'antd';
import {
  ScheduleOutlined,
  LogoutOutlined,
  CalendarOutlined,
  UserOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import { useHistory, useLocation, matchPath, Link } from 'react-router-dom';
import routes from '../lib/routes';
import { isImpersonatedUser } from '../lib/session';
import { ModeContext } from './AppMode';
import ImpersonationAlert from './ImpersonationAlert';

const { Header } = Layout;

const useStyles = createUseStyles({
  logo: {
    width: '120px',
    height: '31px',
    background: 'transparent',
    margin: '16px 24px 16px 0',
    float: 'left',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      display: 'block',
    },
  },
  navbar: {
    height: '64px',
    backgroundColor: 'white',
  },
  alignRight: {
    textAlign: 'right',
  },
});

const menuKeys = {
  home: 'home',
  profile: 'profile',
  availability: 'availability',
  payments: 'payments',
};

const tutorMenu = [
  {
    key: menuKeys.home,
    name: 'Mis clases',
    icon: <ScheduleOutlined />,
    path: routes.home,
  },
  {
    key: menuKeys.availability,
    name: 'Mis disponibilidad',
    icon: <CalendarOutlined />,
    path: routes.availability,
  },
  {
    key: menuKeys.payments,
    name: 'Mis pagos',
    icon: <DollarOutlined />,
    path: routes.payments,
  },
  {
    key: menuKeys.profile,
    name: 'Perfil',
    icon: <UserOutlined />,
    path: routes.profile,
  },
];

function getSelectedMenu(pathname) {
  return Object.values(menuKeys).find((key) => {
    return matchPath(pathname, {
      path: routes[key],
      exact: true,
      strict: false,
    });
  });
}

function Navbar() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { mode, setMode } = useContext(ModeContext);

  const goTo = (path) => {
    history.push(path);
  };

  const signOut = () => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('isAdmin');
    setMode(null);
    goTo(routes.login);
  };

  const returnToAdmin = () => {
    const adminJwt = localStorage.getItem('adminJwt');
    localStorage.setItem('jwt', adminJwt);
    localStorage.removeItem('adminJwt');
    setMode('admin');
  };

  return (
    <>
      {isImpersonatedUser() && <ImpersonationAlert />}
      <Header className={classes.navbar}>
        <Link to={routes.home} className={classes.logo}>
          <img src="/logo.png" alt="Logo" />
        </Link>
        <Menu
          selectedKeys={[getSelectedMenu(location.pathname)]}
          mode="horizontal"
          className={classes.alignRight}
        >
          {mode === 'tutor' &&
            tutorMenu.map((submenu) => {
              return (
                <Menu.Item
                  key={submenu.key}
                  icon={submenu.icon}
                  onClick={() => goTo(submenu.path)}
                >
                  {submenu.name}
                </Menu.Item>
              );
            })}
          {mode === 'admin' && (
            <Menu.Item
              key="payments"
              icon={<DollarOutlined />}
              onClick={() => goTo(routes.payments)}
            >
              Pagos
            </Menu.Item>
          )}
          {isImpersonatedUser() ? (
            <Menu.Item
              key="4"
              icon={<LogoutOutlined />}
              onClick={returnToAdmin}
            >
              Volver a vista admin
            </Menu.Item>
          ) : (
            <Menu.Item key="4" icon={<LogoutOutlined />} onClick={signOut}>
              Cerrar sesión
            </Menu.Item>
          )}
        </Menu>
      </Header>
    </>
  );
}

export default Navbar;
