import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Card, Row, Col, Form, Input, Button, notification } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useParams, useHistory, Link } from 'react-router-dom';
import Logotype from './Logotype';
import routes from '../lib/routes';
import { fetchUrl } from '../lib/api';

const useStyles = createUseStyles({
  emailCard: {
    marginTop: '10%',
  },
});

const PASSWORD_RULES = [
  {
    required: true,
    message: 'Debes ingresar una contraseña',
  },
];

const CONFIRMATION_RULES = [
  PASSWORD_RULES[0],
  ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('No coincide con la contraseña'));
    },
  }),
];

function NewPassword() {
  const classes = useStyles();
  const { token } = useParams();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const updatePassword = (values) => {
    setLoading(true);
    const data = {
      user: { ...values, reset_password_token: token },
    };
    fetchUrl({ method: 'PUT', data, url: '/users/passwords' })
      .then((response) => {
        setLoading(false);
        notification.success({
          message: '¡Listo!',
          description: response.message,
        });
        history.push(routes.login);
      })
      .catch((error) => {
        setLoading(false);
        notification.error({
          message: 'Error',
          description:
            error.message || 'Ha ocurrido un error al enviar los datos',
        });
      });
  };

  return (
    <Row>
      <Col span={24}>
        <Logotype />
      </Col>
      <Col
        xs={{ span: 22, offset: 1 }}
        md={{ span: 18, offset: 3 }}
        xl={{ span: 10, offset: 7 }}
      >
        <Card title="Ingresa tu nueva contraseña" className={classes.emailCard}>
          <Form name="new-password" onFinish={updatePassword}>
            <Form.Item
              name="password"
              label="Contraseña"
              rules={PASSWORD_RULES}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="password_confirmation"
              label="Confirma tu contraseña"
              dependencies={['password']}
              hasFeedback
              rules={CONFIRMATION_RULES}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Guardar nueva contraseña
              </Button>
            </Form.Item>
          </Form>
          <Link to={routes.login}>
            <ArrowLeftOutlined /> Volver al inicio de sesión
          </Link>
        </Card>
      </Col>
    </Row>
  );
}

export default NewPassword;
