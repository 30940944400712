import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    padding: '16px 24px',
  },
});

export default function PageContent({ children }) {
  const classes = useStyles();
  return <section className={classes.container}>{children}</section>;
}

PageContent.propTypes = {
  children: PropTypes.node.isRequired,
};
