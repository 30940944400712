import PropTypes from 'prop-types';
import { Table } from 'antd';
import React, { useMemo } from 'react';
import LessonsPayments from '../LessonsPayment';

function transform(lessons) {
  const byOffice = {};
  const offices = [];
  const hours = {};
  const prices = {};
  const data = lessons.map((lesson) => ({
    ...lesson,
    payment: lesson.status === 'done' ? lesson.price : 0,
  }));

  data.forEach((lesson) => {
    const officeId = lesson.office.id;

    if (!byOffice[officeId]) {
      byOffice[officeId] = [];
      hours[officeId] = 0;
      prices[officeId] = 0;
      offices.push(lesson.office);
    }

    if (lesson.status === 'done') {
      hours[officeId] += lesson.totalDurationInHours;
      prices[officeId] += lesson.payment;
      byOffice[officeId].push(lesson);
    }
  });

  return offices
    .map((office) => ({
      ...office,
      hours: hours[office.id],
      price: new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP',
      }).format(prices[office.id]),
      lessons: byOffice[office.id],
    }))
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
}

export default function ClientPayments({ lessons }) {
  const payments = useMemo(() => lessons && transform(lessons), [lessons]);
  return (
    <Table
      pagination={false}
      loading={!payments}
      dataSource={payments}
      rowKey={(record) => record.id}
      expandable={{
        expandedRowRender: (record) => (
          <LessonsPayments lessons={record.lessons} paymentKey="price" />
        ),
      }}
      columns={[
        { title: 'Oficina', dataIndex: 'name', key: 'name' },
        { title: 'Horas totales de clases', dataIndex: 'hours', key: 'hours' },
        {
          title: 'Monto total a cobrar',
          dataIndex: 'price',
          key: 'price',
        },
      ]}
    />
  );
}

ClientPayments.propTypes = {
  lessons: PropTypes.arrayOf(PropTypes.shape({})),
  paymentPeriod: PropTypes.shape({
    startsAt: PropTypes.string.isRequired,
  }).isRequired,
};

ClientPayments.defaultProps = {
  lessons: undefined,
};
