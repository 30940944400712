import PropTypes from 'prop-types';
import { Button, Form, Input, Modal, message } from 'antd';
import React, { useState } from 'react';
import { fetchUrl } from '../lib/api';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const PASSWORD_RULES = [
  {
    required: true,
    message: 'Es requerido',
  },
];

const PASSWORD_CONFIRMATION_RULES = [
  {
    required: true,
    message: 'Es requerido',
  },
  ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Las contraseñas deben coincidir'));
    },
  }),
];

export default function UpdatePasswordModal({ onCancel, visible }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const updatePassword = (values) => {
    setLoading(true);
    fetchUrl({
      method: 'PUT',
      url: '/profile/password',
      data: { user: values },
    })
      .then((response) => {
        message.success(response.message || 'Contraseña actualizada');
        setLoading(false);
        form.setFieldsValue({ password: '', passwordConfirmation: '' });
        onCancel();
      })
      .catch((error) => {
        message.error(error.message || 'Contraseña actualizada');
        setLoading(false);
      });
  };

  return (
    <Modal
      title="Cambiar contraseña"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => form.submit()}
        >
          {loading ? 'Actualizando...' : 'Actualizar'}
        </Button>,
      ]}
    >
      <Form form={form} {...layout} onFinish={updatePassword}>
        <Form.Item
          name="password"
          label="Contraseña Nueva"
          rules={PASSWORD_RULES}
        >
          <Input.Password type="password" />
        </Form.Item>
        <Form.Item
          name="passwordConfirmation"
          label="Confirmar contraseña"
          dependencies={['password']}
          rules={PASSWORD_CONFIRMATION_RULES}
        >
          <Input.Password type="password" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

UpdatePasswordModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};
