import React, { useState } from 'react';
import {
  Card,
  Descriptions,
  Layout,
  PageHeader,
  Space,
  Button,
  Avatar,
  Alert,
} from 'antd';
import {
  LockOutlined,
  VideoCameraOutlined,
  UserOutlined,
} from '@ant-design/icons';
import useSWR from 'swr';
import Navbar from './Navbar';
import Footer from './Footer';
import fetcher from '../lib/fetcher';
import UpdatePasswordModal from './UpdatePasswordModal';
import AvatarUpdater from './AvatarUpdater';
import TopicSelector from './TopicSelector';
import BankAccount from './BankAccount';
import UpdateMeetingUrlModal from './UpdateMeetingUrlModal';

const { Content } = Layout;

const emptyProfile = {
  firstName: '',
  lastName: '',
  email: '',
  topics: [],
  avatarUrl: '',
};

function Profile() {
  const { data, mutate } = useSWR('/profile', fetcher);
  const profile = data || emptyProfile;
  const topics = profile.topics || [];
  const [isVisibleModal, setVisibleModal] = useState(false);
  const [changeMeetingUrlModal, setChangeMeetingUrlModal] = useState(false);
  const fullName = `${profile.firstName} ${profile.lastName}`;

  const updateAvatar = (newUrl) => {
    mutate({ ...data, avatarUrl: newUrl });
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Navbar />
      <Content style={{ padding: '0 50px' }}>
        <Space direction="vertical">
          <PageHeader title="Perfil" />
          <Card
            title="Información básica"
            actions={[
              <AvatarUpdater onUpdate={updateAvatar} />,
              <Button onClick={() => setVisibleModal(true)}>
                <LockOutlined />
                Cambiar contraseña
              </Button>,
            ]}
          >
            <Descriptions>
              <Descriptions.Item style={{ textAlign: 'center' }}>
                <Avatar
                  shape="square"
                  src={profile.avatarUrl}
                  size={130}
                  icon={<UserOutlined />}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Nombre">{fullName}</Descriptions.Item>
              <Descriptions.Item label="Correo">
                {profile.email}
              </Descriptions.Item>
            </Descriptions>
          </Card>
          {emptyProfile !== profile && (
            <BankAccount name={fullName} email={profile.email} />
          )}
          <TopicSelector topics={topics} />
          <Card
            title="Sala de Reuniones"
            actions={[
              <Button onClick={() => setChangeMeetingUrlModal(true)}>
                <VideoCameraOutlined />
                Actualizar tu link de reuniones
              </Button>,
            ]}
          >
            {profile && profile.meetingRoomUrl ? (
              <>
                <p>
                  Tu enlace es{' '}
                  <a
                    href={profile.meetingRoomUrl}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {profile.meetingRoomUrl}
                  </a>
                </p>
                <Alert
                  message="Nota: no tienes que compartir el enlace. Cuando los
                  estudiantes entren a la clase, automáticamente se les
                  redireccionará a tu sala de reunión."
                  type="info"
                />
              </>
            ) : (
              'Todavía no has configurado tu sala de reuniones.'
            )}
          </Card>
        </Space>
      </Content>
      <Footer />
      <UpdatePasswordModal
        onCancel={() => setVisibleModal(false)}
        visible={isVisibleModal}
      />
      <UpdateMeetingUrlModal
        onCancel={() => setChangeMeetingUrlModal(false)}
        visible={changeMeetingUrlModal}
      />
    </Layout>
  );
}

export default Profile;
