import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import useSWR from 'swr';
import {
  Spin,
  Card,
  Layout,
  PageHeader,
  Space,
  Form,
  Input,
  Checkbox,
  Button,
  notification,
} from 'antd';
import fetcher from '../../lib/fetcher';
import Navbar from '../Navbar';
import PageContent from '../PageContent';
import { fetchUrl } from '../../lib/api';
import { getRoute } from '../../lib/routes';

const validations = {
  email: [
    {
      type: 'email',
      message: 'No es un email válido',
    },
    {
      required: true,
      message: 'Debes ingresar tu email',
    },
  ],
  firstName: [{ required: true, message: 'Es requerido' }],
  lastName: [{ required: true, message: 'Es requerido' }],
};

function getZoomAccount(tutor) {
  return tutor.externalResources.find(
    ({ organization }) => organization.slug === 'zoom',
  );
}

function getInitialValues(tutor) {
  const zoomAccount = getZoomAccount(tutor);

  return {
    firstName: tutor.firstName,
    lastName: tutor.lastName,
    zoomId: zoomAccount ? zoomAccount.externalId : '',
  };
}

function TutorForm({ isSubmitting, tutor, organizations, topics, onSubmit }) {
  const [form] = Form.useForm();
  const [selectedTopics, setSelectedTopics] = useState(() => {
    const state = {};

    (tutor.topics || []).forEach((topic) => {
      const organizationId = topic.organization.id;
      state[`${organizationId}`] = state[`${organizationId}`] || [];
      state[`${organizationId}`].push(`${topic.id}`);
    });

    return state;
  });

  const onTopicSelect = (organizationId, ids) => {
    setSelectedTopics((state) => ({
      ...state,
      [`${organizationId}`]: ids,
    }));
  };

  const onFinish = (values) => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
    };

    const oldTopics = tutor.topics.map((topic) => `${topic.id}`);

    const topicSelection = Object.values(selectedTopics).flat();

    const removedTopics = oldTopics.filter((topicId) => {
      return !topicSelection.includes(topicId);
    });

    const newTopics = topicSelection.filter(
      (topicId) => !oldTopics.includes(topicId),
    );

    payload.authorizedTopicsAttributes = [
      ...removedTopics.map((topicId) => ({
        id: tutor.topics.find((topic) => `${topic.id}` === topicId)
          .authorizedTopicId,
        _destroy: true,
      })),
      ...newTopics.map((topicId) => ({ topicId })),
    ];

    const zoomAccount = getZoomAccount(tutor);

    if (values.zoomId) {
      payload.zoomAccountAttributes = {
        externalId: values.zoomId,
      };

      if (zoomAccount) {
        payload.zoomAccountAttributes.id = zoomAccount.id;
      }
    } else if (zoomAccount) {
      payload.zoomAccountAttributes = {
        id: zoomAccount.id,
        _destroy: true,
      };
    }

    onSubmit(payload);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="login"
      initialValues={getInitialValues(tutor)}
      onFinish={onFinish}
    >
      <Space direction="vertical">
        <Card title="Datos Personales">
          <Form.Item
            name="firstName"
            label="Nombre"
            rules={validations.firstName}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Apellido"
            rules={validations.lastName}
          >
            <Input />
          </Form.Item>
          <Form.Item name="zoomId" label="ID de Usuario en Zoom">
            <Input />
          </Form.Item>
        </Card>
        <Card title="Asignaturas">
          {(organizations || []).map((organization) => (
            <div key={organization.id} style={{ marginBottom: '1rem' }}>
              <h4>{organization.name}</h4>
              <Checkbox.Group
                value={selectedTopics[organization.id] || []}
                onChange={(values) => onTopicSelect(organization.id, values)}
                options={(topics || [])
                  .filter((topic) => topic.organizationId === organization.id)
                  .map((topic) => ({
                    label: topic.name,
                    value: `${topic.id}`,
                  }))}
              />
            </div>
          ))}
        </Card>
        <Button
          loading={isSubmitting}
          size="large"
          type="primary"
          htmlType="submit"
        >
          Guardar
        </Button>
      </Space>
    </Form>
  );
}

TutorForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  tutor: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    externalResources: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        externalId: PropTypes.string.isRequired,
        organization: PropTypes.shape({
          id: PropTypes.number.isRequired,
          slug: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ).isRequired,
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        authorizedTopicId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default function EditTutor() {
  const { id } = useParams();
  const history = useHistory();
  const { data: tutor } = useSWR(`/admin/tutors/${id}`, fetcher);
  const { data: organizations } = useSWR('/admin/organizations', fetcher);
  const { data: topics } = useSWR('/admin/topics', fetcher);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateTutor = async (payload) => {
    setIsSubmitting(true);

    try {
      await fetchUrl({
        method: 'PUT',
        data: payload,
        url: `/admin/tutors/${tutor.id}`,
      });
      notification.success({
        message: 'Listo',
        description: '¡Tutor actualizado!',
      });
      history.replace(getRoute('showTutor', { id: tutor.id }));
    } catch (error) {
      notification.error({
        message: '¡Ups!',
        description:
          error.message || 'Ha ocurrido un error al actualizar al tutor',
      });
      setIsSubmitting(false);
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Navbar />
      <Layout.Content style={{ padding: '0 50px' }}>
        <PageHeader title="Tutor" onBack={() => history.goBack()} />
        <PageContent>
          <Spin tip="Cargando tutor..." spinning={!tutor.id}>
            <Space direction="vertical">
              {tutor && organizations && topics && (
                <TutorForm
                  isSubmitting={isSubmitting}
                  tutor={tutor}
                  organizations={organizations.filter(
                    (organization) => organization.name !== 'Zoom',
                  )}
                  topics={topics}
                  onSubmit={updateTutor}
                />
              )}
            </Space>
          </Spin>
        </PageContent>
      </Layout.Content>
    </Layout>
  );
}
