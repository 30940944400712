import PropTypes from 'prop-types';
import { Table, Tag, Space, Button, message, Modal } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { getRoute } from '../lib/routes';
import { fetchUrl } from '../lib/api';

const organizationColors = {
  sacateun7: '#00BA79',
  gauss: '#2D2D2D',
};

export default function LessonsPayments({
  lessons,
  showActions,
  isCancelAllowed,
  onRefresh,
  paymentKey,
  ...rest
}) {
  const [lessonId, setLessonId] = useState(null);

  const columns = [
    {
      title: 'Organización',
      key: 'organization',
      dataIndex: 'organization',
      render(organization) {
        return (
          <Tag color={organizationColors[organization.slug]}>
            {organization.name}
          </Tag>
        );
      },
    },
    {
      title: 'Asignatura',
      key: 'topic',
      dataIndex: 'topic',
      render(topic) {
        return topic.name;
      },
    },
    {
      title: 'Fecha',
      key: 'date',
      dataIndex: 'startsAt',
      render(startsAt) {
        return moment(startsAt).format('LLLL');
      },
    },
    {
      title: 'Duración',
      key: 'duration',
      render(text, { startsAt, endsAt }) {
        return moment
          .utc(moment(endsAt).diff(moment(startsAt)))
          .format('HH:mm');
      },
    },
    {
      title: 'Pago (Bruto)',
      key: paymentKey,
      dataIndex: paymentKey,
      render(payment) {
        return new Intl.NumberFormat('es-CL', {
          style: 'currency',
          currency: 'CLP',
        }).format(payment);
      },
    },
    {
      title: 'Vouchers',
      key: 'status',
      dataIndex: 'status',
      render(status, lesson) {
        if (!isCancelAllowed) {
          return (
            <Tag color={status === 'done' ? 'green' : 'red'}>
              {status === 'done' ? 'Listo' : 'Faltan Vouchers'}
            </Tag>
          );
        }

        if (status === 'done') {
          return <Tag color="green">Listo</Tag>;
        }

        if (lesson.organization.slug === 'sacateun7') {
          return <Tag color="red">Faltan Vouchers</Tag>;
        }

        return (
          <Space direction="horizontal">
            <Tag color="red">Faltan Vouchers</Tag>
            <Button
              title="Cancelar"
              danger
              disabled={lesson.id === lessonId}
              icon={<CloseCircleOutlined />}
              size="small"
              onClick={() => {
                setLessonId(lesson.id);

                Modal.confirm({
                  title: '¿Quieres suspender la clase?',
                  icon: <ExclamationCircleOutlined />,
                  content: 'Se le devolverán los créditos al alumno',
                  okText: 'Sí, suspender',
                  cancelText: 'No',
                  onCancel() {
                    setLessonId(null);
                  },
                  onOk() {
                    fetchUrl({
                      method: 'POST',
                      url: '/admin/canceled_lessons',
                      data: {
                        canceledLesson: {
                          reason: 'Clase no realizada',
                          lessonId: lesson.id,
                        },
                      },
                    })
                      .then(() => {
                        setLessonId(null);
                        onRefresh();
                        message.success('Clase suspendida exitosamente.');
                      })
                      .catch(() => {
                        setLessonId(null);
                        message.error('La clase no pudo ser suspendida.');
                      });
                  },
                });
              }}
            >
              Cancelar
            </Button>
          </Space>
        );
      },
    },
  ];

  if (showActions) {
    columns.push({
      title: 'Acciones',
      key: 'actions',
      render(lesson) {
        return <Link to={getRoute('lessons', { id: lesson.id })}>Detalle</Link>;
      },
    });
  }

  return (
    <Table
      pagination={false}
      dataSource={lessons}
      rowKey={({ id }) => id}
      columns={columns}
      {...rest}
    />
  );
}

LessonsPayments.propTypes = {
  showActions: PropTypes.bool,
  lessons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      status: PropTypes.oneOf(['done', 'pending']).isRequired,
      payment: PropTypes.number.isRequired,
      startsAt: PropTypes.string.isRequired,
      endsAt: PropTypes.string.isRequired,
      topic: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
      organization: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
  paymentKey: PropTypes.oneOf(['grossPayment', 'price']),
  onRefresh: PropTypes.func,
  isCancelAllowed: PropTypes.bool,
};

LessonsPayments.defaultProps = {
  showActions: false,
  onRefresh: undefined,
  isCancelAllowed: false,
  paymentKey: 'grossPayment',
};
