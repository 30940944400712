import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Layout, PageHeader, DatePicker, Table, Empty, Alert } from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import es from 'antd/es/date-picker/locale/es_ES';
import useSWR from 'swr';
import { useHistory, useLocation } from 'react-router-dom';
import fetcher from '../lib/fetcher';
import { getPath } from '../lib/api';
import Navbar from './Navbar';
import Footer from './Footer';
import LessonsPayments from './LessonsPayment';

const { Content } = Layout;

const useStyles = createUseStyles({
  layout: {
    minHeight: '100vh',
  },
  contentBox: {
    padding: '10px 50px',
  },
  monthPicker: {
    marginBottom: '20px',
  },
  tableSummary: {
    background: '#fafafa',
    fontWeight: 'bold',
  },
});

const TABLE_LOCALE_CONFIG = {
  emptyText: (
    <Empty
      description="No tuviste clases en este mes"
      image={Empty.PRESENTED_IMAGE_SIMPLE}
    />
  ),
};

function getInitialMonth(location) {
  if (!location.search) {
    return moment();
  }
  const params = new URLSearchParams(location.search);
  return moment(params.get('month'));
}

function getQueryParams(month) {
  return {
    startDate: month.startOf('month').format('YYYY-MM-DD'),
    endDate: month.endOf('month').format('YYYY-MM-DD'),
  };
}

function Payments() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [month, setMonth] = useState(getInitialMonth(location));
  const { data, error } = useSWR(
    getPath('/payments', getQueryParams(month)),
    fetcher,
  );
  const lessons = data || [];

  const onChange = (date) => {
    setMonth(date);
  };

  const monthStr = month.startOf('month').format('YYYY-MM-DD');

  useEffect(() => {
    const params = new URLSearchParams();
    params.append('month', monthStr);
    history.push({ search: params.toString() });
  }, [monthStr, history]);

  const generateTableTitle = () => {
    return <h3>Detalle pagos {month.format('MMMM YYYY')}</h3>;
  };

  const generateTableSummary = () => {
    let totalAmount = 0;

    lessons.forEach(({ grossPayment }) => {
      totalAmount += grossPayment;
    });

    const formatedAmount = new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
    }).format(totalAmount);

    return (
      <>
        <Table.Summary.Row className={classes.tableSummary}>
          <Table.Summary.Cell />
          <Table.Summary.Cell />
          <Table.Summary.Cell />
          <Table.Summary.Cell>Total (Bruto)</Table.Summary.Cell>
          <Table.Summary.Cell>{formatedAmount}</Table.Summary.Cell>
          <Table.Summary.Cell />
          <Table.Summary.Cell />
        </Table.Summary.Row>
      </>
    );
  };

  return (
    <Layout className={classes.layout}>
      <Navbar />
      <Content className={classes.contentBox}>
        <PageHeader className={classes.header} title="Mis pagos" />
        <span>Selecciona un mes: </span>
        <DatePicker
          className={classes.monthPicker}
          picker="month"
          locale={es}
          value={month}
          format="MMM/YYYY"
          onChange={onChange}
        />
        {error ? (
          <Alert
            message="Error"
            description="Ha ocurrido un error en la carga de datos"
            type="error"
            showIcon
          />
        ) : (
          <LessonsPayments
            showActions
            lessons={lessons}
            locale={TABLE_LOCALE_CONFIG}
            title={generateTableTitle}
            summary={generateTableSummary}
          />
        )}
      </Content>
      <Footer />
    </Layout>
  );
}

export default Payments;
