import { useCallback, useState } from 'react';
import { message } from 'antd';
import { downloadExcel } from './download';

export default function useExportExcel(url) {
  const [exportingExcel, setExportingExcel] = useState(false);
  const exportExcel = useCallback(async () => {
    setExportingExcel(true);
    try {
      await downloadExcel(url);
    } catch (e) {
      message.error('Ups, ocurrió un problema al generar el archivo Excel');
    }
    setExportingExcel(false);
  }, [url]);

  return [exportingExcel, exportExcel];
}
