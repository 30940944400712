import React from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import routes from '../lib/routes';
import Profile from './Profile';
import Availability from './Availability';
import Lesson from './Lesson';
import Home from './Home';
import Payments from './Payments';
import PrivateRoute from './PrivateRoute';

function AppTutor() {
  return (
    <Router>
      <Switch>
        <PrivateRoute path={routes.profile} component={Profile} />
        <PrivateRoute path={routes.availability} component={Availability} />
        <PrivateRoute path={routes.lessons} component={Lesson} />
        <PrivateRoute path={routes.payments} component={Payments} />
        <PrivateRoute path={routes.home} exact component={Home} />
        <Redirect to={routes.home} />
      </Switch>
    </Router>
  );
}

export default AppTutor;
