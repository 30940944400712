import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getURL } from '../lib/api';

function AvatarUpdater({ onUpdate }) {
  const [isLoading, setLoading] = useState(false);

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
      onUpdate(info.file.response.avatar_url);
    }
  };

  return (
    <Upload
      name="user[avatar]"
      listType="picture"
      showUploadList={false}
      action={getURL('/profile')}
      headers={{
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      }}
      method="PATCH"
      onChange={handleChange}
    >
      <Button loading={isLoading}>
        <UploadOutlined /> Cambiar foto de perfil
      </Button>
    </Upload>
  );
}

AvatarUpdater.propTypes = {
  onUpdate: PropTypes.func.isRequired,
};

export default AvatarUpdater;
