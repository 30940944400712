import resource from 'resource-path';

const routes = {
  login: '/login',
  profile: '/profile',
  availability: '/availability',
  payments: '/payments',
  lessons: '/lessons/:id',
  home: '/',
  showTutor: '/tutors/:id',
  editTutor: '/tutors/:id/edit',
  newTutor: '/tutors/new',
  passwordRecovery: '/password-recovery',
  newPassword: '/new-password/:token',
};

export function getRoute(key, params) {
  return resource(routes[key], params);
}

export default routes;
