export default class ApiError extends Error {
  constructor(status, data) {
    super(
      (data && data.message) || 'Ha ocurrido un error en la carga de datos',
    );
    this.name = 'ApiError';
    this.data = data;
    this.status = status;
  }
}
