import { Typography } from 'antd';
import React from 'react';

export default function Logotype() {
  return (
    <div style={{ textAlign: 'center', marginTop: '1em' }}>
      <img style={{ maxWidth: '150px' }} src="/logo.png" alt="Logo" />
      <Typography.Paragraph>Plataforma de tutores</Typography.Paragraph>
    </div>
  );
}
