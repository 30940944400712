import React from 'react';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import { PageHeader, Descriptions, Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import LessonVoucherStatus from './LessonVoucherStatus';

const useStyles = createUseStyles({
  header: {
    marginTop: '10px',
    padding: '10px 24px',
  },
});

function LessonStudents({ lesson, onReload }) {
  const classes = useStyles();

  return (
    <>
      <PageHeader className={classes.header} title="Alumnos" />
      <Descriptions column={2} bordered layout="vertical">
        <Descriptions.Item label="Nombre alumno">
          {lesson.lessonStudents.map((lessonStudent) => (
            <p key={lessonStudent.student.id}>{lessonStudent.student.name}</p>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="Estado Voucher">
          {lesson.externalUrl ? (
            <Button
              target="_blank"
              href={lesson.externalUrl}
              type="link"
              icon={<ArrowRightOutlined />}
            >
              Ver estado vouchers
            </Button>
          ) : (
            lesson.lessonStudents.map((lessonStudent) => (
              <LessonVoucherStatus
                key={lessonStudent.id}
                isCanceled={lesson.canceled}
                endsDate={lesson.endsAt}
                lessonStudent={lessonStudent}
                onReload={onReload}
              />
            ))
          )}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
}

LessonStudents.propTypes = {
  lesson: PropTypes.shape({
    externalUrl: PropTypes.string,
    canceled: PropTypes.bool,
    endsAt: PropTypes.string,
    lessonStudents: PropTypes.array,
  }).isRequired,
  onReload: PropTypes.func.isRequired,
};

export default LessonStudents;
