import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useSWR from 'swr';
import {
  Spin,
  Card,
  Descriptions,
  Layout,
  PageHeader,
  Avatar,
  Tag,
  Alert,
  Space,
  Button,
} from 'antd';
import { UserOutlined, LinkOutlined } from '@ant-design/icons';
import fetcher from '../../lib/fetcher';
import Navbar from '../Navbar';
import PageContent from '../PageContent';
import routes, { getRoute } from '../../lib/routes';

const organizationColors = {
  sacateun7: '#00BA79',
  gauss: '#2D2D2D',
  zoom: '#0e72ed',
};

const organizationUrls = {
  sacateun7: 'https://coordinador.sacateun7.cl/staff/tutors/:id',
  gauss: 'https://intranet.preugauss.cl/admin/users/:id',
  zoom: 'https://zoom.us/user/:id/profile',
};

export default function ShowTutor() {
  const { id } = useParams();
  const history = useHistory();
  const { data: tutor } = useSWR(`/admin/tutors/${id}`, fetcher, {
    fallbackData: {},
  });

  const externalResources = tutor.externalResources || [];
  const topics = tutor.topics || [];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Navbar />
      <Layout.Content style={{ padding: '0 50px' }}>
        <PageHeader title="Tutor" onBack={() => history.push(routes.home)} />
        <PageContent>
          <Spin tip="Cargando tutor..." spinning={!tutor.id}>
            <Space direction="vertical">
              <Card title="Información Básica">
                <Descriptions>
                  <Descriptions.Item style={{ textAlign: 'center' }}>
                    <Avatar
                      shape="square"
                      src={tutor.avatarUrl}
                      size={130}
                      icon={<UserOutlined />}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Nombre">
                    {tutor.firstName} {tutor.lastName}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email">
                    {tutor.email}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
              <Card title="Asignaturas">
                {topics.length === 0 && <Alert message="Sin asignaturas" />}
                {topics.map((topic) => (
                  <Tag
                    key={topic.id}
                    title={`${topic.name} en ${topic.organization.name}`}
                    color={organizationColors[topic.organization.slug]}
                  >
                    {topic.name}
                  </Tag>
                ))}
              </Card>
              <Card title="Conexiones con otros sistemas">
                {externalResources.length === 0 && (
                  <Alert message="Sin conexiones" />
                )}
                {externalResources.map((externalResource) => (
                  <a
                    key={externalResource.id}
                    href={organizationUrls[
                      externalResource.organization.slug
                    ].replace(':id', externalResource.externalId)}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={`Ver en ${externalResource.organization.name}`}
                  >
                    <Tag
                      icon={<LinkOutlined />}
                      color={
                        organizationColors[externalResource.organization.slug]
                      }
                    >
                      {externalResource.organization.name}
                    </Tag>
                  </a>
                ))}
              </Card>
              <Button
                type="primary"
                onClick={() =>
                  history.push(getRoute('editTutor', { id: tutor.id }))
                }
              >
                Editar
              </Button>
            </Space>
          </Spin>
        </PageContent>
      </Layout.Content>
    </Layout>
  );
}
