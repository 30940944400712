import PropTypes from 'prop-types';
import { Button, Form, Input, Modal, message } from 'antd';
import React, { useState } from 'react';
import { fetchUrl } from '../lib/api';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const MEETING_URL_RULES = [
  {
    required: true,
    message: 'Es requerido',
  },
];

export default function UpdateMeetingUrlModal({ onCancel, visible }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const updatePassword = (values) => {
    setLoading(true);
    fetchUrl({
      method: 'PATCH',
      url: '/profile',
      data: { user: values },
    })
      .then((response) => {
        message.success(response.message || 'Link de reunión actualizado');
        setLoading(false);
        form.setFieldsValue({ meetingRoomUrl: '' });
        onCancel();
      })
      .catch((error) => {
        message.error(
          error.message || 'No pudimos actualizar el link de reunión',
        );
        setLoading(false);
      });
  };

  return (
    <Modal
      title="Sala de Reuniones"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => form.submit()}
        >
          {loading ? 'Actualizando...' : 'Actualizar'}
        </Button>,
      ]}
    >
      <p>
        Entra a{' '}
        <a
          href="https://meet.google.com/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Google Meet
        </a>
        , y crea una una reunión instantánea. Copia y luego pega el link a
        continuación.
      </p>
      <Form form={form} {...layout} onFinish={updatePassword}>
        <Form.Item
          name="meetingRoomUrl"
          label="Link de reunión"
          rules={MEETING_URL_RULES}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

UpdateMeetingUrlModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};
