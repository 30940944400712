import downloadjs from 'downloadjs';
import { fetchUrl } from './api';

const MIME = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  pdf: 'application/pdf',
};

function getFileNameFromResponse(response, extension) {
  const contentDisposition = response.headers.get('content-disposition');
  const result = /filename="(.+?)"/.exec(contentDisposition);
  if (result && result[1]) {
    return result[1];
  }
  return `export.${extension}`;
}

async function downloadFile(url, extension) {
  const response = await fetchUrl({
    method: 'GET',
    url,
    headers: {
      Accept: MIME[extension],
    },
  });
  const blob = await response.blob();
  const filename = getFileNameFromResponse(response, extension);
  downloadjs(blob, filename, MIME[extension]);
}

export function downloadPDF(path) {
  return downloadFile(path, 'pdf');
}

export function downloadExcel(path) {
  return downloadFile(path, 'xlsx');
}
