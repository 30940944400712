import React, { useEffect, useState, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams, useHistory } from 'react-router-dom';
import {
  Layout,
  PageHeader,
  Descriptions,
  Spin,
  notification,
  Button,
  Modal,
  Input,
} from 'antd';
import {
  ReadOutlined,
  ProfileOutlined,
  ClockCircleOutlined,
  HourglassOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Navbar from './Navbar';
import Footer from './Footer';
import LessonStudents from './LessonStudents';
import { fetchUrl } from '../lib/api';
import routes from '../lib/routes';
import CancelLessonButton from './CancelLessonButton';

const { Content } = Layout;
const { TextArea } = Input;

const useStyles = createUseStyles({
  layout: {
    minHeight: '100vh',
  },
  contentBox: {
    padding: '10px 50px',
  },
  header: {
    padding: '10px 24px',
  },
  buttonContainer: {
    marginTop: '20px',
  },
  reasonFooter: {
    fontSize: '12px',
  },
});

function Lesson() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [lesson, setLesson] = useState({
    topic: {},
    lessonStudents: [],
  });
  const [isLoading, setLoading] = useState(true);
  const [isVisibleModal, setVisibleModal] = useState(false);
  const [reason, setReason] = useState('');

  const fetchLesson = useCallback(() => {
    setLoading(true);
    fetchUrl({ method: 'GET', url: `/lessons/${id}` })
      .then((respJSON) => {
        setLesson(respJSON);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notification.error({
          message: 'Error',
          description:
            error.message || 'Ha ocurrido un error en la carga de datos',
        });
      });
  }, [id]);

  useEffect(() => {
    fetchLesson();
  }, [fetchLesson]);

  const cancelLesson = () => {
    setLoading(true);
    const data = {
      lessonId: id,
      reason,
    };
    fetchUrl({ method: 'POST', data, url: '/canceled_lessons' })
      .then(() => {
        setVisibleModal(false);
        history.push(routes.home);
        notification.success({
          description: 'La clase ha sido cancelada',
        });
      })
      .catch((error) => {
        setLoading(false);
        notification.error({
          message: 'Error',
          description:
            error.message || 'Ha ocurrido un error en la carga de datos',
        });
      });
  };

  return (
    <Layout className={classes.layout}>
      <Navbar />
      <Content className={classes.contentBox}>
        <Spin tip="Cargando información..." spinning={isLoading}>
          <PageHeader className={classes.header} title="Detalles de clase" />
          <Descriptions column={1} bordered>
            <Descriptions.Item
              label={
                <span>
                  <ReadOutlined /> Asignatura
                </span>
              }
            >
              {lesson.topic.name}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <span>
                  <ProfileOutlined /> Temario
                </span>
              }
            >
              {lesson.description || 'No hay información ingresada'}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <span>
                  <ClockCircleOutlined /> Fecha y hora
                </span>
              }
            >
              {moment(lesson.startsAt).format('LLLL')}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <span>
                  <HourglassOutlined /> Duración
                </span>
              }
            >
              {moment(lesson.endsAt).diff(lesson.startsAt, 'minutes')} minutos
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <span>
                  <VideoCameraOutlined /> Información para ingresar
                </span>
              }
            >
              <Button
                type="primary"
                size="large"
                href={`${lesson.roomUrl}?jwt=${localStorage.getItem('jwt')}`}
              >
                <VideoCameraOutlined /> Haz clic para ingresar a la clase
              </Button>
            </Descriptions.Item>
          </Descriptions>
          <LessonStudents lesson={lesson} onReload={fetchLesson} />
          {lesson.cancelable && (
            <div className={classes.buttonContainer}>
              <CancelLessonButton
                onClick={() => setVisibleModal(true)}
                lesson={lesson}
              />
            </div>
          )}
        </Spin>
      </Content>
      {isVisibleModal && (
        <Modal
          title="Cancelar clase"
          visible
          cancelText="Cerrar"
          okText="Cancelar clase"
          onOk={cancelLesson}
          onCancel={() => {
            setVisibleModal(false);
          }}
          okButtonProps={{ disabled: reason.length < 6 }}
        >
          <p>Ingresa el motivo para cancelar:</p>
          <TextArea
            allowClear
            onChange={(event) => {
              setReason(event.target.value);
            }}
          />
          <span className={classes.reasonFooter}>
            Ingresa un motivo con 6 o más carácteres
          </span>
        </Modal>
      )}
      <Footer />
    </Layout>
  );
}

export default Lesson;
