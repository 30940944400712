export default function compactObject(object) {
  if (!object) return undefined;

  const compacted = {};

  Object.keys(object).forEach((key) => {
    if (object[key] !== undefined && object[key] !== null) {
      compacted[key] = object[key];
    }
  });

  return compacted;
}
