function isAuthenticated() {
  return !!localStorage.getItem('jwt');
}

export function isAdmin() {
  return !!localStorage.getItem('isAdmin');
}

export function isImpersonatedUser() {
  return !!localStorage.getItem('adminJwt');
}

export default isAuthenticated;
